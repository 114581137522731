import React, {useState} from "react";
import {v4 as uuid} from "uuid";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    deleteProductionTaskOrderStatus,
    LABELS_CUTTING_PRINT,
    removeOrderFromProductionTaskThunk
} from "../../store/reducers/MainReducer";
import ApiService from "../../api/api";
import {withLang} from "../../hoc/withLang";
import ButtonPrint from "../Orders/OrderDetailPrint/ButtonPrint";
import OrderDetailPrintGl from "../Orders/OrderDetailPrint/OrderDetailPrintGl";
import useClickOutside from "../../hook/useClickOutside";
import CuttingCard from "../Modals/CuttingCard";
import StatusProjectList from "./StatusProject/StatusProjectList";
import {STATUS_PROJECT_ORDER_PRODUCTION} from "./StatusProject/consts";
import LabelsPrintCount from "../Modals/LabelsPrintCount";
import {gaEvents} from "../../gaEvents";
import PackagingPrintingModal from "../Modals/PackagingPrintingModal";

const OrdersGroupDetail = (props) => {
    let glLink = new ApiService()._glLink;

    const dispatch = useDispatch();

    const {setVisible: setOpenPrint, ref, isVisible: openPrint} = useClickOutside()
    const [isModalCuttingCard, modalCuttingCard] = useState(false);
    const [isModalPackagingPrinting,modalPackagingPrinting] = useState(false);
    const [actionsPrint, setActionsPrint] = useState({
        'order_blank_short_html': false,
        'order_blank_short_without_html': false,
        'material_list_html': false,
        'cutting_card': false,
        'ifp_xnc': false
    });

    const [modal, setModal] = useState({
        isOpen: false,
        action: '',
        grid: 1
    });
    const onChangeValueModal = (values)=>{
        setModal(prevState => ({
            ...prevState,
            ...values
        }))
    }
    const onSubmitLabelsPrint = ()=>{
        // LABELS_CUTTING_PRINT.ifurn
        props.getLabelsPrintHtml({action: modal.action, project: props.productionTask.project_data, grid: modal.grid });

    }
    const getDiscount = (id, type) => {
        if (props.discounts.material && props.discounts.service) {
            if (type === "material") {
                return props.discounts.material.filter(
                    (e) => Number(e.id) === Number(id)
                )[0].price;
            }
            if (type === "service") {
                return props.discounts.service.filter(
                    (e) => Number(e.id) === Number(id)
                )[0]
                    ? props.discounts.service.filter(
                        (e) => Number(e.id) === Number(id)
                    )[0].price
                    : 0;
            }
        }
    };

    const getTotal = (calculate) => {
        if (calculate && calculate.materials) {
            let total = Number(0);
            total += calculate.materials.reduce(function (accumulator, currentValue) {
                return (
                    accumulator +
                    currentValue.count *
                    (getDiscount(currentValue.goods, "material")
                        ? getDiscount(currentValue.goods, "material")
                        : currentValue.price)
                );
            }, 0);
            total += calculate.services.reduce(function (accumulator, currentValue) {
                return (
                    accumulator +
                    currentValue.count *
                    (getDiscount(currentValue.services, "service")
                        ? getDiscount(currentValue.services, "service")
                        : currentValue.price)
                );
            }, 0);
            total += calculate.extra.reduce(function (accumulator, currentValue) {
                return accumulator + currentValue.count * currentValue.price;
            }, 0);
            return total.toFixed(2);
        } else {
            return 0;
        }
    };


    return (
        props.productionTask && (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h3> {props.getT("Информация о заказе")} </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{props.getT("Основная информация")}</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                                    <div className="row">
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            {props.getT("Сумма заказа")}
                          </span>
                                                    <span className="info-box-number text-center text-muted mb-0">
                            {props.productionTask.confirm &&
                                +Number(props.productionTask.confirm.cost).toFixed(2)}{" "}
                                                        {props.productionTask.currency &&
                                                            props.productionTask.currency.name}
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            {props.getT("Дата заказа")}
                          </span>
                                                    <span className="info-box-number text-center text-muted mb-0">
                            {props.productionTask.date}
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                        {" "}
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            {props.getT("Количество заказов")}
                          </span>
                                                    <span className="info-box-number text-center text-muted mb-0">
                            {props.productionTask.childs &&
                                props.productionTask.childs.length}
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            {props.getT("Количество деталей")}
                          </span>
                                                    {/*<span className="info-box-number text-center text-muted mb-0">{props.order.project_data.part.length}</span>*/}
                                                    <span className="info-box-number text-center text-muted mb-0">
                            {props.productionTask.project_data &&
                                props.productionTask.project_data.part.reduce(
                                    (total, e) => {
                                        return Number(total) + Number(e.count);
                                    },
                                    0
                                )}
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6 order-1 order-md-2">
                                    <p className="text-muted">{props.productionTask.comment}</p>
                                    <br/>
                                    <div className="text-muted">
                                        <p className="text-sm">
                                            {props.getT(" Номер заказа")}:
                                            <b className="d-block">
                                                {props.productionTask.code_reg} (
                                                {props.productionTask.code} / {props.productionTask.id})
                                            </b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Номер заказа 1C")}:
                                            <b className="d-block">{props.productionTask.code1c}</b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Валюта заказа")}:
                                            <b className="d-block">
                                                {props.productionTask.currency &&
                                                    props.productionTask.currency.name}
                                            </b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Наличие ЧПУ операций")}:
                                            <b className="d-block">
                                                {Number(props?.productionTask?.xnc_operations) === 1 || (props?.productionTask?.childs?.filter((item) => item?.have_operations)?.length)
                                                    ? props.getT("присутствуют")
                                                    : props.getT("отсутствуют")}
                                            </b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Менеджер")}:
                                            <b className="d-block">
                                                {props.productionTask.manager &&
                                                props.productionTask.manager.name ? (
                                                    props.productionTask.manager.name
                                                ) : (
                                                    <>
                                                        <span>{props.getT("Не указано")}</span>
                                                    </>
                                                )}
                                            </b>
                                        </p>
                                        {/*<p className="text-sm">*/}
                                        {/*    <div>{props.getT("Статус")}: OLD</div>*/}
                                        {/*    <div className="order_status_container">*/}
                                        {/*        <span*/}
                                        {/*            className={*/}
                                        {/*                "badge bg-" +*/}
                                        {/*                getStatus(props.productionTask.status).color*/}
                                        {/*            }*/}
                                        {/*        >*/}
                                        {/*          {props.getT(getStatus(props.productionTask.status).name)}*/}
                                        {/*        </span>*/}
                                        {/*        {getStatus(props.status).id !== 1 ? (*/}
                                        {/*            <i*/}
                                        {/*                className="fas fa-edit"*/}
                                        {/*                onClick={() =>*/}
                                        {/*                    props.toggleChangeStatus(*/}
                                        {/*                        Number(props.productionTask.id) ===*/}
                                        {/*                        Number(props.changedStatus)*/}
                                        {/*                            ? null*/}
                                        {/*                            : props.productionTask.id*/}
                                        {/*                    )*/}
                                        {/*                }*/}
                                        {/*            >*/}
                                        {/*                {" "}*/}
                                        {/*            </i>*/}
                                        {/*        ) : (*/}
                                        {/*            ""*/}
                                        {/*        )}*/}

                                        {/*        <div*/}
                                        {/*            className={*/}
                                        {/*                "orders_status_change_container" +*/}
                                        {/*                (Number(props.productionTask.id) ===*/}
                                        {/*                Number(props.changedStatus)*/}
                                        {/*                    ? " active"*/}
                                        {/*                    : "")*/}
                                        {/*            }*/}
                                        {/*        >*/}
                                        {/*            <small>{props.getT("Установить новый статус")}:</small>*/}
                                        {/*            <hr/>*/}
                                        {/*            {props.productionTask.status === "8" ? (*/}
                                        {/*                <div*/}
                                        {/*                    className={"change_status_variant"}*/}
                                        {/*                    onClick={() =>*/}
                                        {/*                        props.updateOrder(props.productionTask.id, {*/}
                                        {/*                            status: "1"*/}
                                        {/*                        })*/}
                                        {/*                    }*/}
                                        {/*                >*/}
                                        {/*                  <span>*/}
                                        {/*                    <span*/}
                                        {/*                        onClick={() =>*/}
                                        {/*                            props.toggleChangeStatus(*/}
                                        {/*                                Number(props.productionTask.id) ===*/}
                                        {/*                                Number(props.changedStatus)*/}
                                        {/*                                    ? null*/}
                                        {/*                                    : props.productionTask.id*/}
                                        {/*                            )*/}
                                        {/*                        }*/}
                                        {/*                        className={"badge bg-" + "primary"}*/}
                                        {/*                    >*/}
                                        {/*                      {props.getT("В редактирование")}*/}
                                        {/*                    </span>*/}
                                        {/*                  </span>*/}
                                        {/*                </div>*/}
                                        {/*            ) : (*/}
                                        {/*                props.status.map((s) => {*/}
                                        {/*                    if (checkStatus(props.userRoles, s.roles)) {*/}
                                        {/*                        return (*/}
                                        {/*                            <div*/}
                                        {/*                                key={s.id}*/}
                                        {/*                                className={"change_status_variant"}*/}
                                        {/*                                onClick={() =>*/}
                                        {/*                                    props.updateOrder(*/}
                                        {/*                                        props.productionTask.id,*/}
                                        {/*                                        {*/}
                                        {/*                                            status: s.id*/}
                                        {/*                                        }*/}
                                        {/*                                    )*/}
                                        {/*                                }*/}
                                        {/*                            >*/}
                                        {/*                                <span>*/}
                                        {/*                                  <span*/}
                                        {/*                                      onClick={() =>*/}
                                        {/*                                          props.toggleChangeStatus(*/}
                                        {/*                                              Number(props.productionTask.id) ===*/}
                                        {/*                                              Number(props.changedStatus)*/}
                                        {/*                                                  ? null*/}
                                        {/*                                                  : props.productionTask.id*/}
                                        {/*                                          )*/}
                                        {/*                                      }*/}
                                        {/*                                      className={"badge bg-" + s.color}*/}
                                        {/*                                  >*/}
                                        {/*                                    {props.getT(s.name)}*/}
                                        {/*                                  </span>*/}
                                        {/*                                </span>*/}
                                        {/*                            </div>*/}
                                        {/*                        );*/}
                                        {/*                    }*/}
                                        {/*                })*/}
                                        {/*            )}*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</p>*/}
                                        <p className="text-sm order-group-detail-status">
                                            <div>{props.getT("Статус")}:</div>
                                            <StatusProjectList getT={props.getT} projectId={props.productionTask.id} onUpdateStatus={props.updateOrder} projectStatus={props.productionTask.status}
                                                          userRoleEntry={props.userRoles} statusList={props.status} rules={STATUS_PROJECT_ORDER_PRODUCTION} onDeleteProductionTaskThunk={(id)=>{
                                                          dispatch(deleteProductionTaskOrderStatus(id, '/orders/group'))}
                                            }
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6 order-1 order-md-2 order-detail-pdf-container">
                                    {props.productionTask.project_data &&
                                    props.productionTask.project_data.calculate_vals ? (
                                        <>
                                            <div className={'order-detail-pdf__items'}>
                                                <ButtonPrint getT={props.getT}
                                                             onClick={() => props.getPDF("order_blank_short_html", props.productionTask.project_data)}
                                                    // onClick={() => props.getPDF("order_blank_short", props.order.project_data)}
                                                             typeActionChildren={''}
                                                             isFileIcon={true}
                                                             titleChildren={<>{props.getT("Бланк заказа")}</>}
                                                />

                                                <ButtonPrint getT={props.getT}
                                                             onClick={() => props.getPDF("order_blank_short_without_html", props.productionTask.project_data)}
                                                             typeActionChildren={''}
                                                             isFileIcon={true}
                                                             titleChildren={<>{props.getT("Бланк заказа ")}
                                                                 <br/> {props.getT("(производство)")}</>}
                                                />

                                                <ButtonPrint getT={props.getT}
                                                             onClick={() => props.getPDF("order_blank", props.productionTask.project_data)}
                                                             typeActionChildren={''}
                                                             isFileIcon={true}
                                                             titleChildren={<>{props.getT("Сводный отчет")}
                                                                 <br/> {props.getT("ЧПУ")}</>}
                                                />

                                                {Number(props.productionTask.xnc_operations) === 1 ? (
                                                    <ButtonPrint getT={props.getT}
                                                                 onClick={() => props.getPDF("ifp_xnc", props.productionTask.project_data)}
                                                                 typeActionChildren={''}
                                                                 isFileIcon={true}
                                                                 titleChildren={<>{props.getT("Чертежи ЧПУ")}<br/>{props.getT("операций")}</>}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                                <ButtonPrint getT={props.getT}
                                                             onClick={() => props.getPDF("material_list_html", props.productionTask.project_data)}
                                                             typeActionChildren={''}
                                                             isFileIcon={true}
                                                             titleChildren={<>{props.getT("Накладная на сырье")}</>}
                                                />

                                            </div>
                                            <div className={'order-detail-pdf__items'}>
                                                <ButtonPrint getT={props.getT}
                                                             onClick={() => props.getGiblabDataThunk("renew_cards_crm", props.productionTask)}
                                                             typeActionChildren={<>{props.getT("API")}</>}
                                                             isFileIcon={true}
                                                             titleChildren={<>{props.getT("Обновить раскрой")}</>}
                                                />
                                                {/*TODO: ссылка под замену (АПИ)*/}
                                                <a
                                                    className="btn btn-app bg-info"
                                                    // href={`https://gl.ifurn.pro?code=${props.productionTask.project_data.code}&cs=1`}
                                                    // href={`https://test-gl.ifurn.pro?code=${props.productionTask.project_data.code}&cs=1`}
                                                    // href={`https://test2-gl.ifurn.pro?code=${props.productionTask.project_data.code}&cs=1`}
                                                    href={`${glLink}?code=${props.productionTask.project_data.code}&cs=1`}
                                                    // href={`http://gl-local.ifurn.pro?code=${props.productionTask.project_data.code}&cs=1`}
                                                    target={"_blank"}
                                                >
                                                    <span className="badge bg-danger">{props.getT("Giblab")}</span>
                                                    <i className="far fa-file-alt"></i> {props.getT("Изменить раскрой")}
                                                </a>
                                                {props.productionTask.project_data?.cutting_card?.hasOwnProperty('cards') ?
                                                    <ButtonPrint getT={props.getT}
                                                                 onClick={() => modalCuttingCard(true)}
                                                                 typeActionChildren={''}
                                                                 isFileIcon={true}
                                                                 titleChildren={<>{props.getT("Карты раскроя")}</>}
                                                    /> : ''}
                                                {props.productionTask.project_data?.hasOwnProperty('packing') && Array.isArray(props.productionTask.project_data?.packing) && props.productionTask.project_data?.packing?.length ?
                                                    <ButtonPrint getT={props.getT}
                                                                 onClick={() => {
                                                                     gaEvents.customEvent('klik_form', {
                                                                         packing: 'Упаковки',
                                                                     })
                                                                     modalPackagingPrinting(true)
                                                                 }}
                                                                 typeActionChildren={''}
                                                                 isFileIcon={true}
                                                                 titleChildren={<>{props.getT("Упаковка")}</>}
                                                    /> : ''}
                                                <ButtonPrint getT={props.getT}
                                                             onClick={() => {
                                                                 onChangeValueModal({isOpen: true, action: LABELS_CUTTING_PRINT.ifurn})

                                                                 // props.getLabelsPrintHtml({
                                                                 //     action: LABELS_CUTTING_PRINT.ifurn,
                                                                 //     project: props.productionTask.project_data
                                                                 // });
                                                             }}
                                                             typeActionChildren={''}
                                                             isFileIcon={true}
                                                             titleChildren={<>{props.getT("Этикетки")}</>}
                                                />
                                                <OrderDetailPrintGl getT={props.getT} order={props.productionTask}
                                                                    onClicklLabels={() => {
                                                                        onChangeValueModal({isOpen: true, action: LABELS_CUTTING_PRINT.giblab})
                                                                        // props.getLabelsPrintHtml({
                                                                        //     action: LABELS_CUTTING_PRINT.giblab,
                                                                        //     project: props.productionTask.project_data
                                                                        // });
                                                                    }
                                                                    }
                                                                    getPdfHandler={props.getPDF}/>
                                                {/*{props.productionTask.xnc_operations === "1" ? (*/}
                                                {/*  <a*/}
                                                {/*    className="btn btn-app bg-info"*/}
                                                {/*    onClick={() =>*/}
                                                {/*      props.getPDF(*/}
                                                {/*        "xnc",*/}
                                                {/*        props.productionTask.project_data*/}
                                                {/*      )*/}
                                                {/*    }*/}
                                                {/*  >*/}
                                                {/*    <span className="badge bg-danger">{props.getT("Giblab")}</span>*/}
                                                {/*    <i className="far fa-file-alt"></i> {props.getT("Чертежи ЧПУ ")} <br/>{props.getT(" операций")}*/}
                                                {/*  </a>*/}
                                                {/*) : (*/}
                                                {/*  ""*/}
                                                {/*)}*/}
                                                {/*<a*/}
                                                {/*  className="btn btn-app bg-info"*/}
                                                {/*  onClick={() =>*/}
                                                {/*    props.getPDF(*/}
                                                {/*      "pdf",*/}
                                                {/*      props.productionTask.project_data*/}
                                                {/*    )*/}
                                                {/*  }*/}
                                                {/*>*/}
                                                {/*  <span className="badge bg-danger">{props.getT("Giblab")}</span>*/}
                                                {/*  <i className="far fa-file-alt"></i> {props.getT("Полный отчет")}*/}
                                                {/*</a>*/}
                                            </div>
                                            <div className={'order-detail-pdf__items'}>
                                                <div className={"print_all_documents_main_container"} ref={ref}>
                                                    <ButtonPrint getT={props.getT}
                                                                 onClick={() => {
                                                                     setOpenPrint(!openPrint)
                                                                     // props.changePrintDocumentsData("isOpen", !props.printDocumentsData.isOpen)
                                                                 }}
                                                                 typeActionChildren={<>{props.getT("Print")}</>}
                                                                 isFileIcon={true}
                                                                 titleChildren={<> {props.getT("Печать пакета")} {props.getT("документов")}</>}
                                                    />

                                                    {/*<a*/}
                                                    {/*  className="btn btn-app bg-info"*/}
                                                    {/*  onClick={() =>*/}
                                                    {/*    props.changePrintDocumentsData(*/}
                                                    {/*      "isOpen",*/}
                                                    {/*      !props.printDocumentsData.isOpen*/}
                                                    {/*    )*/}
                                                    {/*  }*/}
                                                    {/*>*/}
                                                    {/*  <span className="badge bg-danger">{props.getT("Print")}</span>*/}
                                                    {/*  <i className="far fa-file-alt"></i> {props.getT("Печать пакета")} <br/>  {props.getT(" документов")}*/}
                                                    {/*</a>*/}
                                                    <div
                                                        className={
                                                            "print_all_documents_container " +
                                                            (openPrint &&
                                                            openPrint
                                                                ? "open"
                                                                : "")
                                                        }

                                                    >
                                                        <div className="form-group">
                                                            <label htmlFor="">
                                                                <input
                                                                    type="checkbox"
                                                                    className={"form-control"}
                                                                    onClick={() => setActionsPrint((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            ['order_blank_short_html']: !actionsPrint['order_blank_short_html']
                                                                        }
                                                                    })}
                                                                    // props.changePrintDocumentsData(
                                                                    //     "order_blank_short",
                                                                    //     !props.printDocumentsData.order_blank_short
                                                                    // )
                                                                    // }
                                                                    checked={actionsPrint['order_blank_short_html']
                                                                        // props.printDocumentsData.order_blank_short
                                                                    }
                                                                />
                                                                <span>{props.getT("Бланк заказа")}</span>
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">
                                                                <input
                                                                    type="checkbox"
                                                                    className={"form-control"}
                                                                    onClick={() => setActionsPrint((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            ['order_blank_short_without_html']: !actionsPrint.order_blank_short_without_html
                                                                        }
                                                                    })}

                                                                    checked={actionsPrint.order_blank_short_without_html}

                                                                />
                                                                <span>{props.getT("Бланк заказа (производство)")}</span>
                                                            </label>
                                                        </div>
                                                        {props.productionTask.project_data?.cutting_card?.hasOwnProperty('cards') ?
                                                            <div className="form-group">
                                                                <label htmlFor="">
                                                                    <input
                                                                        type="checkbox"
                                                                        className={"form-control"}
                                                                        onClick={() => setActionsPrint((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                ['cutting_card']: !actionsPrint.cutting_card
                                                                            }
                                                                        })}
                                                                        checked={actionsPrint.cutting_card}
                                                                    />
                                                                    <span>{props.getT("Карты кроя")}</span>
                                                                </label>
                                                            </div> : ''}
                                                        <div className="form-group">
                                                            <label htmlFor="">
                                                                <input
                                                                    type="checkbox"
                                                                    className={"form-control"}
                                                                    onClick={() => setActionsPrint((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            ['material_list_html']: !actionsPrint.material_list_html
                                                                        }
                                                                    })}
                                                                    // onClick={() =>
                                                                    //     props.changePrintDocumentsData(
                                                                    //         "material_list",
                                                                    //         !props.printDocumentsData.material_list
                                                                    //     )
                                                                    // }
                                                                    checked={actionsPrint.material_list_html}
                                                                    // checked={props.printDocumentsData.material_list}
                                                                />
                                                                <span>{props.getT("Накладная на сырье")}</span>
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    props.fetchingMergeHtml(actionsPrint, props.productionTask.project_data)

                                                                }
                                                                    // props.printDocumentsPackage(
                                                                    //     props.order.project_data
                                                                    // )
                                                                }
                                                            >
                                                                {props.getT("Печать документов")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a
                                                    className="btn btn-app bg-info"
                                                    onClick={() =>
                                                        props.getPDF(
                                                            "gibsaw_ftp",
                                                            props.productionTask.project_data
                                                        )
                                                    }
                                                >
                                                    <span className="badge bg-danger">{props.getT("Download")}</span>
                                                    <i className="far fa-file-alt"></i> {props.getT("Программы и")}
                                                    <br/> {props.getT(".project")}
                                                </a>
                                                <a
                                                    className="btn btn-app bg-info"
                                                    onClick={() =>
                                                        props.getPDF(
                                                            "get_ifp_project",
                                                            props.productionTask.project_data
                                                        )
                                                    }
                                                >
                                                    <span className="badge bg-danger">{props.getT("Download")}</span>
                                                    <i className="far fa-file-alt"></i> {props.getT("Скачать IFP")}
                                                    <br/>{props.getT("project")}
                                                </a>
                                            </div>

                                            {/*<a*/}
                                            {/*    className="btn btn-app bg-info"*/}
                                            {/*    onClick={() =>*/}
                                            {/*        props.getPDF(*/}
                                            {/*            "renew_cards_crm",*/}
                                            {/*            props.productionTask.project_data*/}
                                            {/*        )*/}
                                            {/*    }*/}
                                            {/*>*/}
                                            {/*  <span className="badge bg-danger">{props.getT("API")}</span>*/}
                                            {/*  <i className="far fa-file-alt"></i> {props.getT("Создать новый раскрой")}*/}
                                            {/*</a>*/}

                                        </>
                                    ) : props.productionTask?.project_data ? (
                                        <div className={'order-detail-pdf__items'}>
                                            <ButtonPrint getT={props.getT}
                                                         onClick={() => props.getPDF("material_list_html", props.productionTask.project_data)}
                                                         typeActionChildren={''}
                                                         isFileIcon={true}
                                                         titleChildren={<>{props.getT("Накладная на сырье")}</>}
                                            />
                                            <ButtonPrint getT={props.getT}
                                                         onClick={() => props.getPDF("order_blank_short_html", props.productionTask.project_data)}
                                                // onClick={() => props.getPDF("order_blank_short", props.order.project_data)}
                                                         typeActionChildren={''}
                                                         isFileIcon={true}
                                                         titleChildren={<>{props.getT("Бланк заказа")}</>}
                                            />
                                            <ButtonPrint getT={props.getT}
                                                         onClick={() => props.getGiblabDataThunk("renew_cards_crm", props.productionTask)}
                                                         typeActionChildren={<>{props.getT("API")}</>}
                                                         isFileIcon={true}
                                                         titleChildren={<>{props.getT("Создать новый раскрой")}</>}
                                            />
                                            {/*<a*/}
                                            {/*  className="btn btn-app bg-info"*/}
                                            {/*  onClick={() =>*/}
                                            {/*    props.getPDF(*/}
                                            {/*      "renew_cards_crm",*/}
                                            {/*      props.productionTask.project_data*/}
                                            {/*    )*/}
                                            {/*  }*/}
                                            {/*>*/}
                                            {/*  <span className="badge bg-danger">{props.getT("API")}</span>*/}
                                            {/*  <i className="far fa-file-alt"></i> {props.getT("Создать новый раскрой")}*/}
                                            {/*</a>*/}
                                            <ButtonPrint getT={props.getT} onClick={() => {
                                                props.getPDF("get_ifp_project", props.productionTask.project_data)
                                            }}
                                                         typeActionChildren={<>{props.getT("Download")}</>}
                                                         isFileIcon={true}
                                                         titleChildren={<>{props.getT("Скачать IFP project")}</>}
                                            />
                                            <ButtonPrint getT={props.getT}
                                                         onClick={() => props.getPDF("order_blank", props.productionTask.project_data)}
                                                         typeActionChildren={''}
                                                         isFileIcon={true}
                                                         titleChildren={<>{props.getT("Сводный отчет")}
                                                             <br/> {props.getT("ЧПУ")}</>}
                                            />
                                            {/*<a*/}
                                            {/*  className="btn btn-app bg-info"*/}
                                            {/*  onClick={() =>*/}
                                            {/*    props.getPDF(*/}
                                            {/*      "order_blank",*/}
                                            {/*      props.productionTask.project_data*/}
                                            {/*    )*/}
                                            {/*  }*/}
                                            {/*>*/}
                                            {/*  <span className="badge bg-danger">{props.getT("PDF")}</span>*/}
                                            {/*  <i className="far fa-file-alt"></i> {props.getT("Полный бланк")} <br/>{props.getT("с ЧПУ")}*/}
                                            {/*</a>*/}
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{props.getT("Заказы")}</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                    <div className="table-responsive">
                                        <table className="table m-0 table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">{props.getT("Тех.ID")}</th>
                                                <th scope="col">{props.getT("Название")}</th>
                                                <th scope="col">{props.getT("Дата производства")}</th>
                                                <th scope="col">{props.getT("Дата")}</th>
                                                <th scope="col">{props.getT("Действие")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {props.productionTask.childs &&
                                                props.productionTask.childs.map((el) => {
                                                    return (
                                                        <tr>
                                                            <td data-label={props.getT("ID")}>{el.id}</td>
                                                            <td data-label={props.getT("Название")}>{el.name}</td>
                                                            <td data-label={props.getT("Дата производства")}>
                                                                {el.plan_of_production}
                                                            </td>
                                                            <td data-label={props.getT("Дата")}>{el.date}</td>
                                                            <td>
                                                                <Link
                                                                    target="_blank"
                                                                    to={"/orders/detail/" + el.id}
                                                                >
                                                                    <i className="far fa-eye"> </i>
                                                                </Link>
                                                                <span
                                                                    onClick={(e) =>
                                                                        dispatch(
                                                                            removeOrderFromProductionTaskThunk({
                                                                                action: "remove-orders",
                                                                                orders: [e.target.id],
                                                                                task: props.productionTask.id
                                                                            })
                                                                        )
                                                                    }
                                                                >
                                    <span className="text-danger cursor-pointer ml-10">
                                      <i id={el.id} className="fas fa-ban "></i>
                                    </span>
                                  </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{props.getT("Содержимое заказа")}</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                    <div className="table-responsive">
                                        <table className="table m-0 table-hover">
                                            <thead>
                                            <tr>
                                                {/* <th scope="col">Код</th> */}
                                                <th scope="col">{props.getT("Название")}</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col">{props.getT("Количество")}</th>
                                                <th scope="col">{props.getT("Цена")}</th>
                                                <th scope="col">{props.getT("Сумма")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className={"bg-lightgray"}>
                                                <td colSpan="6" className={"text-center"}>
                                                    <b>{props.getT("Материалы и товары")}</b>
                                                </td>
                                            </tr>

                                            {props.productionTask.calculate &&
                                                props.productionTask.calculate.materials.map((e) => (
                                                    <tr key={uuid()}>
                                                        {/* <td data-label="КОД">{e.goods}</td> */}
                                                        <td data-label={props.getT("Название")}>
                                                            {e.name}
                                                            <p style={{color: "red"}}>
                                                                {e.name_info_bad}
                                                            </p>
                                                            <p style={{color: "green"}}>
                                                                {e.name_info_good}
                                                            </p>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td data-label={props.getT("Количество")}>
                                                            {Number(e.count).toFixed(3)}
                                                        </td>
                                                        <td data-label={props.getT("Цена")}>
                                                            {Number(e.price).toFixed(2)}{" "}
                                                            {props.productionTask.currency &&
                                                                props.productionTask.currency.name}
                                                        </td>
                                                        <td data-label={props.getT("Сумма")}>
                                                            {(Number(e.price) * Number(e.count)).toFixed(2)}{" "}
                                                            {props.productionTask.currency &&
                                                                props.productionTask.currency.name}
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr className={"bg-lightgray"}>
                                                <td colSpan="6" className={"text-center"}>
                                                    <b>{props.getT("Услуги")}</b>
                                                </td>
                                            </tr>
                                            {props.productionTask.calculate &&
                                                props.productionTask.calculate.services.map((e) => (
                                                    <tr key={uuid()}>
                                                        {/* <td data-label="КОД">{e.services}</td> */}
                                                        <td data-label={props.getT("Название")}>{e.name}</td>
                                                        <td className={"text-center"}></td>
                                                        <td></td>
                                                        <td data-label={props.getT("Количество")}>
                                                            {Number(e.count).toFixed(3)}
                                                        </td>
                                                        <td data-label={props.getT("Цена")}>
                                                            {Number(e.price).toFixed(2)}{" "}
                                                            {props.productionTask.currency &&
                                                                props.productionTask.currency.name}
                                                        </td>
                                                        <td data-label={props.getT("Сумма")}>
                                                            {(Number(e.price) * Number(e.count)).toFixed(2)}{" "}
                                                            {props.productionTask.currency &&
                                                                props.productionTask.currency.name}
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr className={"bg-lightgray"}>
                                                <td colSpan="6" className={"text-center"}>
                                                    <b>{props.getT("Дополнительные услуги")}</b>
                                                </td>
                                            </tr>
                                            {props.productionTask.calculate &&
                                                props.productionTask.calculate.extra.map((e) => (
                                                    <tr key={uuid()}>
                                                        {/* <td data-label="КОД">{e.orders_goods_id}</td> */}
                                                        <td data-label={props.getT("Название")}>{e.extra}</td>
                                                        <td></td>
                                                        <td data-label={props.getT("Количество")}>
                                                            {Number(e.count).toFixed(2)}
                                                        </td>
                                                        <td data-label={props.getT("Цена")}>
                                                            {Number(e.price).toFixed(2)}{" "}
                                                            {/* {props.order.currency &&
                                  props.order.currency.name} */}
                                                        </td>
                                                        <td data-label={props.getT("Сумма")}>
                                                            {(Number(e.price) * Number(e.count)).toFixed(2)}{" "}
                                                            {/* {props.order.currency.name} */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td colSpan={4}></td>
                                                <td>
                                                    <b>{props.getT("Итого")}:</b>
                                                </td>
                                                <td>
                                                    <h4 className={"text-primary"}>
                                                        {getTotal(props.productionTask.calculate)}{" "}
                                                        {props.productionTask.currency &&
                                                            props.productionTask.currency.name}
                                                    </h4>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {props.productionTask.messages &&
                    props.productionTask.messages.length > 0 ? (
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{props.getT("История сообщений по заказу")}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                        <div className="table-responsive">
                                            <table className="table m-0 table-hover message_pop_up">
                                                <thead>
                                                <tr>
                                                    <th scope="col">{props.getT("ID")}</th>
                                                    <th scope="col">{props.getT("Дата")}</th>
                                                    <th scope="col">{props.getT("Сообщение")}</th>
                                                    <th scope="col">{props.getT("Таймлайн")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {props.productionTask.messages.map((e) => {
                                                    return e.message ? (
                                                        <tr key={uuid()}>
                                                            <td>{e.id}</td>
                                                            <td>{e.date}</td>
                                                            <td>{e.message}</td>
                                                            <td>
                                                                <OrderTimeline timeline={e.timeline}/>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ""
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </section>
                {isModalCuttingCard ?
                    <CuttingCard getT={props.getT} order={props.productionTask} isOpen={isModalCuttingCard}
                                 onClose={() => {
                                     modalCuttingCard(false)
                                 }}/> : ''}
                <LabelsPrintCount onChange={onChangeValueModal} getT={props.getT} isOpen={modal.isOpen} key={'gird-before-print'} onClose={()=> onChangeValueModal({isOpen: false})} grid={modal.grid} onHandlerSubmit={onSubmitLabelsPrint}/>
                {isModalPackagingPrinting ? <PackagingPrintingModal getT={props.getT} order={props.productionTask} onClose={()=>modalPackagingPrinting(false)} isOpen={isModalPackagingPrinting}/> : ''}

            </div>
        )
    );
};

export default withLang(OrdersGroupDetail);

class OrderTimeline extends React.Component {
    constructor(props) {
        //rst.ua/oldcars/mercedes/e-class/mercedes_e-class_12468267.html
        https: super(props);
        this.state = {
            isOpen: false
        };
    }

    render() {
        if (this.state.isOpen) {
            return (
                <div className={"order_detail_timeline_container"}>
                    <div className={"text-right"}>
            <span onClick={() => this.setState({isOpen: false})}>
              <i className="fas fa-times"> </i>
            </span>
                    </div>
                    <table className="table">
                        <tbody>
                        {this.props.timeline.map((t) => {
                            return (
                                <tr key={uuid()}>
                                    <td>{t.status}</td>
                                    <td> |</td>
                                    <td>{t.date}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <span onClick={() => this.setState({isOpen: true})}>
          <i className="far fa-eye"> </i>
        </span>
            );
        }
    }
}
