import React, {useState} from 'react';
import ItemGoodsInputCheckbox from "../../../components/WholeSale/TreeMaterial/ItemGoodsInputCheckbox";
import {getDisabledByMaterialTypeOrGoods} from "../../../utils/wholesale_utils";
import {WHOLESALE_MATERIAL_TYPE_STATUS} from "../../../constans/whole_sale_constants";

const TreeMaterialTypeList = ({
                                  item,
                                  getT,
                                  type_operation = null,
                                  updateStatusForMaterial,
                                  handlerOpenGoodsSetupModal,
                                  goods_retail_price_direction,
                                  handlerOpenGoodsRetailPriceModal
                              }) => {
    const [isOpen, setToggleOpen] = useState(false);
    const handlerChangeGoods = ({
                                    item,
                                    goods = null,
                                }) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        updateStatusForMaterial({
            value_checked: e.target.checked, current_material: item, goods: goods,
        })
    }


    let disabled_material_type = getDisabledByMaterialTypeOrGoods({
        material_type: item, goods: null, type_operation: type_operation
    })
    const handlerToggleOpen = (e) => {
        if (!!(item?.goods?.length || item?.sub_materials?.length)) {
            setToggleOpen(!isOpen)
        }
        return null
    }

    return (<div style={{padding: '0px 0px 0px 0px', gap: '10px'}} className={"d-flex flex-column "}>
        <div className={"d-flex align-items-center justify-content-between flex-row"}>
            <div className={"d-flex align-items-center" + (disabled_material_type ? "  " : ' ')} >
                <ItemGoodsInputCheckbox status={item.status}
                                        disabled={disabled_material_type}
                                        goods={null}
                                        type_operation={type_operation}
                                        handlerChange={handlerChangeGoods({
                                            item: item,
                                        })}
                />
                <div className={"cursor-pointer"} onClick={handlerToggleOpen}>

                    {item?.folder_name}
                </div>

            </div>

            {!!(item?.goods?.length || item?.sub_materials?.length) &&

                <button onClick={() => setToggleOpen(!isOpen)} className={`btn px-1 py-0 cursor-pointer`}>
                    <span className={"mr-2 text-secondary"}>({item?.goods_count})</span>
                    <i className={`fas fa-angle-${isOpen ? 'up' : 'down'}`}></i>
                </button>
            }
        </div>

        {isOpen &&

            <>
                {item?.goods?.map(goods => {
                    let disabled_goods = getDisabledByMaterialTypeOrGoods({
                        material_type: item, goods: goods, type_operation: type_operation
                    });
                    // let goods_retail_price = true;
                    let goods_retail_price = goods_retail_price_direction?.[goods?.goods_id] ?? null;
                    let is_display_icon_for_open_modal = type_operation === WHOLESALE_MATERIAL_TYPE_STATUS.included && !!Number(goods?.status[type_operation])
                    return <div style={{padding: '0px 0px 0px 10px'}} key={goods?.goods_id}>
                        <div className={"d-flex flex-nowrap align-items-start"} style={{gap: "10px"}}>
                            <div style={{flex: '1 1 auto'}}>
                                <ItemGoodsInputCheckbox status={goods.status}
                                                        disabled={disabled_goods}
                                                        type_operation={type_operation}
                                                        handlerChange={handlerChangeGoods({
                                                            goods: goods,
                                                            item: item,
                                                        })}
                                />
                                [{goods?.goods_id}] {goods?.name}
                            </div>

                            {goods_retail_price && <button className={"btn btn-info px-1 py-0 d-flex flex-nowrap align-items-center"}
                                                           onClick={handlerOpenGoodsRetailPriceModal({
                                                               goods: goods,
                                                               retail_price: goods_retail_price
                                                           })} style={{
                                fontSize: '12px',
                                height: '28px',
                                flex: '0 0 auto'
                            }} title={getT("Настройка товара оптовиком")}>{goods_retail_price?.recommended_retail_price} {goods_retail_price?.currency_name}</button>}
                            {is_display_icon_for_open_modal && <button className="btn btn-success px-0 py-0" style={{
                                fontSize: '12px',
                                width: '28px',
                                height: '28px',
                                flex: '0 0 28px'
                            }} title={getT("Настройка остатков для розничной и оптовой продажи")}
                                                                       onClick={handlerOpenGoodsSetupModal(goods)}>$</button>}
                        </div>
                    </div>
                })}
                {item?.sub_materials?.map((e, index) => (<div style={{padding: '0px 0px 0px 10px'}}>
                    <TreeMaterialTypeList key={e?.folder_id} item={e} getT={getT} type_operation={type_operation}
                                          goods_retail_price_direction={goods_retail_price_direction}
                                          handlerOpenGoodsRetailPriceModal={handlerOpenGoodsRetailPriceModal}
                                          updateStatusForMaterial={updateStatusForMaterial}
                                          handlerOpenGoodsSetupModal={handlerOpenGoodsSetupModal}
                    />
                </div>))
                }
            </>


        }
    </div>);
};

export default TreeMaterialTypeList;